import i18next from 'i18next';
import { GroupBase } from 'react-select';
import { FormConfig } from '../../../models/Form';
import { FormTypeKeys } from '../../../models/FormTypes';
import { groupBy } from '../../../utils/ListUtils';
import { Item } from '../../shared/form-control/DropdownDefaultComponents';
import LanguageUtils from '../../../utils/LanguageUtils';

export const createFormTemplateSource = (data: FormConfig[]): GroupBase<Item>[] => {
  const grouped = groupBy(data, 'type');
  const templateForms: GroupBase<Item>[] = [];

  for (const [key, value] of grouped) {
    templateForms.push({
      label: i18next.t(FormTypeKeys[key]),
      options: value
        .map((template) => {
          const title = LanguageUtils.getTranslation('title', template.translations) + (template.code ? ` - (${template.code})` : '');
          return {
            id: template.id,
            text: `${title} ${template.archivedUtc ? i18next.t('form-builder:action-properties.subform.template-archived') : ''}`,
            value: template.version,
            disabled: !!template.archivedUtc,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text)),
    });
  }
  return templateForms;
};
